<template>
  <b-card>
    <template #header>
      <card-header
        :title="legalityLabel"
        extra-link="https://www.agcm.it/competenze/rating-di-legalita/"
      />
    </template>
    <div>
      <span class="legality-symbols">
          <div v-if="ratingValue">
            <font-awesome-icon
              v-for="(st,idx) in ratingStars"
              :key="'stars'+idx"
              icon="star"
              class="text-warning"
            />

            <font-awesome-icon
              v-for="(st,idx) in ratingPluses"
              :key="'plus'+idx"
              icon="plus"
              class="text-warning"
            />
          </div>
          <div v-else>
            <p>
              <em>
                {{ratingNaMessage}}
              </em>
            </p>
          </div>
        </span>
    </div>
    <p
      v-if="ratingValue"
      class="mt-3 mb-0"
    >
      <em>
        {{dataExpiry}}
      </em>
    </p>
  </b-card>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';
import { formatDateOnly } from '@/utils/formatterHelper';

const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'ProfileTabsDataLegality',
  components: { CardHeader },
  props: {
    data: Object,
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    legalityRating() {
      return this.data.ratingLegalita;
    },
    expiryDateValue() {
      console.log('data', this.data);
      if (isNotEmpty(this.legalityRating?.scadenzaRevocaAnnullamentoSospensione)) {
        return formatDateOnly(this.legalityRating.scadenzaRevocaAnnullamentoSospensione);
      }
      return this.notAvailableMsg;
    },
    legalityLabel() {
      return 'Rating legalità';
    },
    dataExpiry() {
      return `(scadenza: ${this.expiryDateValue})`;
    },
    ratingValue() {
      return this.legalityRating?.rating;
    },
    ratingStars() {
      // console.log('ratingValue', this.ratingValue, this.ratingValue.split('*').length - 1);
      // return (this.ratingValue.match('*') || []).length;
      return this.ratingValue.split('*').length - 1;
    },
    ratingPluses() {
      return this.ratingValue.split('+').length - 1;
    },
    ratingNaMessage() {
      return 'L\'azienda non presenta un rating di legalità';
    },
  },
  methods: {},
};
</script>

<style scoped>
.legality-symbols{
  font-size: 1.5rem;
}
</style>
